<template>
  <div class="privacy-agreement">
    <h5 class="title">隐私协议</h5>
    <div class="privacy-content">
      <p style="text-align: center;"><strong>隐私协议</strong></p>
<p><strong>&nbsp;</strong></p>
<p><strong>【前言】</strong>&nbsp;MetaIO（下文简称&ldquo;我们&rdquo;和&ldquo;我们的&rdquo;）一向庄严承诺保护使用MetaIO产品和服务（以下统称&ldquo;服务&rdquo;）之用户（以下统称&ldquo;用户&rdquo;或&ldquo;您&rdquo;）的个人数据和隐私安全。您在使用MetaIO服务时，我们可能会收集和使用您的相关个人数据。我们希望通过《MetaIO隐私政策》（以下简称&ldquo;本政策&rdquo;）向您说明我们在收集和使用您相关个人数据时对应的处理规则等相关事宜，以便更好地保障您的权益。本政策中，MetaIO指元宇未来科技（北京）有限公司及其关联公司。</p>
<p>&nbsp;</p>
<p><strong>【特别提示】本政策适用于显示本隐私政策、或链接至本隐私政策的MetaIO网站及MetaIO提供的所有在线服务。当您使用我们任何单项服务时，您同意接受本政策以及我们在该单项服务中设置的特定隐私信息类政策条款（以下称&ldquo;特定条款&rdquo;）的保护，在此情况下特定条款与本政策条款同时对您产生效力。如特定条款与本政策条款存在同类条款的不一致约定，则在特定条款约束范围内应以特定条款为准。如我们提供的某一单项服务不适用本政策的，该服务中会以适当方式明示排除适用本政策。</strong></p>
<p>&nbsp;</p>
<p><strong>在使用各项MetaIO服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。一旦您开始使用MetaIO服务或向MetaIO提交个人数据，即表示您已充分理解并同意本政策。</strong>&nbsp;如对本政策内容有任何疑问、意见或建议，您可通过MetaIO官方网站所载联系方式与我们联系。</p>
<p>&nbsp;</p>
<p>本政策阐述了MetaIO如何处理您的个人数据，但本政策可能并不涉及所有可能的数据处理情境。</p>
<p>&nbsp;</p>
<p><strong>我们制定本政策的目的在于帮助您了解以下内容：</strong></p>
<ol>
<li>MetaIO如何收集和使用您的个人数据</li>
<li>MetaIO如何使用 Cookie 和同类技术</li>
<li>MetaIO如何共享、转让、公开披露您的个人数据</li>
<li>您的权利及行使方式</li>
<li>MetaIO如何保护和留存您的个人数据</li>
<li>MetaIO如何处理未成年人的个人数据</li>
<li>您的个人数据如何在全球范围转移</li>
<li>本政策如何更新</li>
<li>如何联系MetaIO</li>
</ol>
<p>&nbsp;</p>
<ol>
<li><strong>MetaIO如何收集和使用您的个人数据</strong></li>
</ol>
<p>1.1&nbsp;我们收集您的个人数据主要是为了您和其他用户能够更容易和更满意地使用MetaIO服务。</p>
<p>1.2&nbsp;我们将通过以下途径收集和获得您的个人数据：</p>
<p>1.2.1&nbsp;您提供的信息。例如：</p>
<p>（a）您在注册MetaIO服务的账号或使用MetaIO服务时，向我们提供的信息；</p>
<p>（b）您通过MetaIO服务向第三方提供的共享信息，以及您使用MetaIO服务时所存储的信息。</p>
<p><strong>请注意，如您在MetaIO服务中其他用户可见的公开区域内上传或发布信息、您对其他人上传或发布的信息作出的回应中公开您的信息，该等信息可能会被他人收集并加以使用。当您发现他人不正当地收集或使用您的信息时，可通过本政策所列的反馈渠道联系我们。</strong></p>
<p>1.2.2&nbsp;第三方共享的您的信息。亦即第三方使用MetaIO服务时所提供有关您的共享信息。</p>
<p>1.2.3&nbsp;我们获取的您的信息。您在使用MetaIO服务时，我们收集、汇总、记录的信息，例如日志信息、位置信息、设备信息。</p>
<p>1.3&nbsp;我们会出于以下目的，收集和使用您以下类型的个人数据：</p>
<p><strong>我们需要特别提醒您的是：由于我们的服务较多，为您提供的内容也不同，因此相关业务功能（包括其收集的您的个人数据类型）也会因您选择的服务的内容不同而有所区别，具体请您以实际使用的服务为准。</strong>&nbsp;除此之外，您理解并同意，我们希望提供给您的服务是完善的，所以我们会不断改进我们的服务，这意味着随着服务的迭代、拓展、升级等，我们可能会经常推出新的服务或业务功能或重新划分业务功能，并可能需要收集新的个人数据或变更个人数据使用目的或方式。&nbsp;<strong>如果我们要将您的个人数据用于本政策中未载明的其他用途或额外收集未提及的其他个人数据，我们会依法通过页面提示、交互流程、网站公告或其他方式另行征得您的同意。</strong>&nbsp;在此过程中，您可通过本政策所列的反馈渠道联系我们，我们会尽快为您作出解答。</p>
<p><strong>1.3.1&nbsp;帮助您完成注册及登录</strong></p>
<p>为便于我们为您提供持续稳定的服务并保障您使用MetaIO服务的安全性，我们需要您提供基本注册或登录信息，例如您的姓名、职位、公司名称、地址、电话号码、电子邮件地址、用户名及密码。在部分单项服务中，如果您仅需使用浏览、搜索等基本功能，您不需要注册成为MetaIO用户及提供上述信息。您也可以在注册、登录或后续使用过程中，填写或补充您的相关信息，这将有助于我们给您提供个性化和更优的服务体验，我们可通过以上信息向您营销推广、宣传产品、发送业务通知、与您进行业务沟通或进行用户画像分析工作。如果您不提供这些信息，并不会影响您使用MetaIO服务的基本功能，具体以您实际使用的服务及相关协议为准。</p>
<p><strong>1.3.2&nbsp;实现身份认证</strong></p>
<p>依照相关法律法规规定及监管要求，或为满足用户身份真实核验、保障系统和服务安全及其他特定服务功能等需要，<strong>我们可能需要您提供真实身份信息（例如姓名、身份证、护照、驾驶证、户口本）、生物识别信息（静态或动态）及其他身份信息以完成身份认证。</strong>如果您不提供上述信息，我们将不能向您提供相关的功能及服务。为实现身份认证的目的，&nbsp;<strong>您同意并授权我们可能以自行或委托第三方向有关身份认证机构（如个人征信机构、政府部门等）提供、查询、核对您的前述身份信息。请注意，您的身份信息、生物识别信息等属于敏感个人数据，请您谨慎提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用，具体以您实际使用的服务及相关协议为准。</strong></p>
<p><strong>1.3.3&nbsp;维护基本功能的正常运行</strong></p>
<p>在您使用我们服务过程中，为向您提供浏览、搜索等基本功能，并识别帐号异常状态、了解产品适配性、保障MetaIO服务的网络及运营安全，以维护前述基本功能的正常运行，&nbsp;<strong>我们可能会直接或者间接地收集、存储关于您使用的服务以及使用方式的信息并将这些信息进行关联，这些信息包括：日志信息、设备信息等（为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们基本服务和基础功能，如您拒绝提供上述权限将可能导致您无法使用MetaIO部分服务）。</strong></p>
<p><strong>请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人数据与其他信息结合用于识别特定自然人身份，或者将其与个人数据结合使用，则在结合使用期间，这类非个人数据将被视为个人数据，除取得您授权或法律法规另有规定外，我们会将该类个人数据做匿名化、去标识化处理。这些信息在结合使用期间，将作为您的个人数据按照本政策处理与保护。</strong></p>
<p><strong>1.3.4&nbsp;向您提供互动与发布服务</strong></p>
<p>您在使用MetaIO服务时、上传和/或发布信息以及进行相关互动行为（例如发布跟贴、回复跟贴）时，我们将收集您上传、发布或形成的信息，并使用您的昵称、头像（若有）和发布内容以作展示。</p>
<p>如您表示有兴趣获取有关我们服务的其他信息，包括使用我们的&ldquo;联系我们&rdquo;&ldquo;预约体验&rdquo;&ldquo;产品试用&rdquo;或类似功能，我们可能会要求您向我们提供您的联系信息，例如您的姓名、职位、公司名称、地址、电话号码、电子邮件地址。</p>
<p>如果您参加MetaIO活动，我们可能会在征得您的进一步同意后，扫描您的参与者证明，该证明将向我们提供您的信息，例如姓名、职位、公司名称、地址、国家、电话号码和电子邮件地址。</p>
<p><strong>请注意，您公开发布的信息中可能包含您的个人数据或敏感个人数据，请您在发布前慎重考虑。如您发布的信息涉及到他人的个人数据的，您应当在发布前征得他人同意。</strong></p>
<p><strong>1.3.5&nbsp;帮助您完成下单、支付并向您交付商品或服务</strong></p>
<p>（a）为向您展示账号的交易信息并保障交易安全，我们会收集您在使用MetaIO服务过程中产生的信息（例如您所购买的商品或服务信息、具体订单号、订单创建时间、交易金额）用于向您展示及便于您对订单进行管理；</p>
<p>（b）为向您展示账号的交易信息并保障交易安全，我们会收集您在使用MetaIO服务过程中产生的信息（例如您所购买的商品或服务信息、具体订单号、订单创建时间、交易金额）用于向您展示及便于您对订单进行管理；</p>
<p>（c）为完成订单支付、交付商品或服务、确认交易状态及为您提供售后与争议解决服务，我们会通过您基于交易所选择的交易对象、支付机构等收集与交易进度相关的您的账号、订单、交易、支付信息，或将您的交易信息共享给上述服务提供者。</p>
<p><strong>1.3.6&nbsp;为您提供客服或其他用户响应功能</strong></p>
<p>当您联系我们的客服或使用其他用户响应功能时（例如提出售中售后申请、个人数据保护投诉或建议、其他客户投诉和需求），<strong>我们可能需要您提供必要的个人数据以匹配并核验您的用户身份，以便保障您的账号与系统安全。我们可能还会保存您的联系方式（您与我们联系时使用的或您向我们主动提供的其他联系方式）、您与我们的通信/通话记录和内容、与您需求相关联的其他必要信息，</strong>以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。</p>
<p><strong>1.3.7&nbsp;我们通过间接方式收集到的您的个人数据</strong></p>
<p>在法律允许的情况下，我们会从第三方来源获取有关服务，例如，我们通过从其他公司购买数据分析服务来支持我们为您提供的服务。基于您选择使用的MetaIO服务，我们可能从关联方、第三方合作伙伴获取您授权共享的相关信息。例如，当您使用第三方平台的账号登录MetaIO服务时，我们将根据您的授权获取该第三方账号下的相关信息（包括：用户名、昵称、头像，具体以您的授权内容为准），<strong>并在您同意本政策后将您的第三方账号与您的MetaIO服务账号绑定，使您可以通过第三方账号直接登录并使用MetaIO服务。</strong>我们将在符合相关法律法规规定，并依据与关联方或第三方合作伙伴的约定、确信其提供的信息来源合法的前提下，收集并使用您的这些信息。</p>
<p><strong>1.3.8&nbsp;为您提供安全保障</strong></p>
<p>为提高您使用我们及合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或MetaIO服务相关协议规则的情况，我们可能会收集、使用或整合您的账号信息、交易信息、设备信息、日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账号及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。</p>
<p><strong>1.3.9&nbsp;您理解并同意，部分单项服务可能需要您在您的设备中开启特定的访问权限，以实现这些权限所涉及信息的收集和使用。</strong></p>
<p><strong>在某些情况下，您可以选择不向MetaIO提供个人数据，但如果您选择不提供，MetaIO可能无法为您提供相关产品或服务，也可能无法回应或解决您所遇到的问题。</strong></p>
<p><strong>1.3.10&nbsp;您理解并同意，MetaIO还可能直接收集和使用您的非识别性数据。非识别性数据是指无法用于确定个人身份的数据。例如，MetaIO会收集汇总的统计数据、网站的访问量、产品及功能的使用情况等非识别数据。MetaIO收集非识别数据的目的在于了解用户如何使用自己的网站、产品和服务以及进行用户画像相关的分类分析工作。我们会尽力隔离您的个人数据和非识别性数据，并单独使用这两种数据。如果个人数据掺杂了非识别性数据，依旧会被视作个人数据处理。</strong></p>
<p><strong>1.3.11&nbsp;征得授权同意的例外</strong></p>
<p>根据相关法律法规规定，以下情形中收集您的信息无需征得您的授权同意：</p>
<p>（a）与国家安全、国防安全直接相关的；</p>
<p>（b）与公共安全、公共卫生、重大公共利益直接相关的；</p>
<p>（c）与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
<p>（d）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
<p>（e）所收集的个人数据是您自行向社会公众公开的；</p>
<p>（f）根据您的要求签订和履行合同所必需的；</p>
<p>（g）从合法公开披露的信息中收集个人数据的，如合法的新闻报道、政府信息公开等渠道；</p>
<p>（h）开展合法的新闻报道所必需的；</p>
<p>（i）法律法规规定的其他情形。</p>
<p><strong>1.3.12&nbsp;有关敏感个人数据的提示</strong></p>
<p>敏感个人数据，是指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人数据。以上由您提供或我们收集您的信息中，可能包含您的敏感个人数据，例如身份证件号码、个人生物识别信息（静态或动态）、银行账号、通信记录和内容、财产信息、行踪轨迹、住宿信息、交易信息。&nbsp;<strong>请您谨慎并留意敏感个人数据，您同意我们可以按本政策所述的目的和方式来处理您的敏感个人数据。</strong></p>
<p>&nbsp;</p>
<ol start="2">
<li><strong>MetaIO如何使用 Cookie 和同类技术</strong></li>
</ol>
<p>2.1&nbsp;Cookie</p>
<p>为确保网站正常运转，我们有时会在计算机或移动设备上存储名为&nbsp;Cookie&nbsp;的小数据文件。Cookie&nbsp;是一种网络服务器存储在计算机或移动设备上的纯文本文件。Cookie&nbsp;的内容只能由创建它的服务器检索或读取。每个&nbsp;Cookie&nbsp;对您的网络浏览器或移动应用程序都是唯一的。Cookie&nbsp;通常包含标识符、站点名称以及一些号码和字符。</p>
<p>MetaIO启用Cookie的目的与大多数网站或互联网服务提供商启用&nbsp;Cookie&nbsp;的目的一样，即改善用户体验。借助于&nbsp;Cookie，网站能够记住用户的单次访问（使用会话&nbsp;Cookie）或多次访问（使用永久&nbsp;Cookie）。借助于&nbsp;Cookie，网站能够保存设置，例如计算机或移动设备的语言、字体大小和其他浏览偏好。这意味着，用户无需在每次访问时重新配置用户偏好设置。MetaIO不会将&nbsp;Cookie&nbsp;用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除&nbsp;Cookie。有关详情，请参见&nbsp;&nbsp;<a href="https://www.aboutcookies.org/"><strong><u>AboutCookies.org&nbsp;</u></strong></a>。 您可以清除计算机上保存的所有&nbsp;Cookie，大部分网络浏览器都设有阻止&nbsp;Cookie&nbsp;的功能。但如果您这么做，在某些情况下可能会影响您安全访问我们的网站，且可能需要在每一次访问我们的网站时更改用户设置。</p>
<p>2.2&nbsp;网站信标和像素标签</p>
<p>除&nbsp;Cookie&nbsp;外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，MetaIO向您发送的电子邮件可能含有链接至MetaIO网站内容的点击&nbsp;URL。如果您点击该链接，MetaIO则会跟踪此次点击，帮助我们了解您的产品和服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时通过MetaIO官方网站所载联系方式联系我们进行退订。</p>
<p>您使用我们的网站意味着您同意按照如上所述使用Cookie，网站信标和像素标签。</p>
<p>&nbsp;</p>
<ol start="3">
<li><strong>MetaIO如何共享、转让、公开披露您的个人数据</strong></li>
</ol>
<p>3.1&nbsp;<strong>我们可能会与MetaIO</strong>&nbsp;<strong>集团、我们的业务合作伙伴、第三方服务提供商以及向您提供对我们服务的访问的人士（如果并非您本人），以及依据法律的规定共享您的个人数据。</strong>&nbsp;我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人数据，并且只会共享提供服务所必要的个人数据。&nbsp;<strong>我们的合作伙伴、第三方服务提供商无权将共享的个人数据用于提供服务之外的任何其他用途</strong>。 对我们与之共享个人数据的公司、组织和个人，我们会对其数据安全环境进行调查，与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人数据。我们出于本政策中所陈述的目的，将出于以下目的与MetaIO之外的任何第三方共享您的个人数据：</p>
<p>(a)&nbsp;为实现我们的服务/功能所必需进行的共享。在某些情况下，我们需要向第三方共享您的信息，以实现我们产品与/或服务的功能，让您正常使用您需要的服务，可能包括：(1)向为您提供对我们服务的访问权限的人员（例如，您的雇主或我们的订阅方）共享您的个人数据（2）向提供支付服务的第三方支付机构共享对应的交易订单信息和支付金额信息；（3）向实名认证服务商共享实名认证所需资料；（4）其他与我们的服务/功能相关的供应商、服务提供商和业务合作伙伴所必需进行的共享。</p>
<p>(b)&nbsp;与我们的部分合作伙伴的必要共享。为了必要/合理的业务的顺利开展、满足您的要求、履行我们在相关用户协议或本政策中的义务和行使我们的权利或遵守法律规定等目的，我们可能需要向部分合作伙伴共享您的信息，包括：（1）向提供数据服务（包括网络广告监测、数据统计、数据分析等）的合作伙伴共享日志信息、设备信息；（2）向提供产品分析服务（包括应用崩溃分析、网络性能分析、域名解析优化）的合作伙伴共享您的产品使用情况（如崩溃、闪退）的统计性数据；（3）向提供营销服务（包括广告投放、市场营销）的合作伙伴共享您的相关信息（包括：设备信息）；（4）向委托我们进行推广的合作伙伴共享有多少人看了他们的推广信息或在看到这些信息后购买了的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客；（5）经合理要求，向出于保护我们的权利、用户、系统和服务目的第三方（例如，法律顾问和数据安全专业人员）共享您的个人数据；（6）其他与我们的部分合作伙伴的必要共享。</p>
<p>(c)&nbsp;与我们的关联方的必要共享。为便于我们基于统一的账号体系向您提供一致化服务以及便于您进行统一管理、个性化推荐、保障系统和账号安全等，您的个人数据可能会在我们和我们的关联方之间进行必要共享，包括：1）为了帮助您节省时间、简化操作流程，您在注册我们的MetaIO账号后无需重复注册即可登录部分单项服务，您的账号相关信息会在我们和我们的关联方的产品内部进行必要共享；2）为了使您在我们的及我们关联方产品与/或服务有一致性和个性化的浏览、广告等服务体验，您知悉并同意，您的账号信息、设备信息、相关浏览数据等反映您使用习惯和爱好的信息可能会与我们的关联方共享。</p>
<p>(d)&nbsp;任何您要求我们与之共享数据的人员（例如，如果您将数据上传到该等数据被公开分享的公共论坛）。</p>
<p><strong>3.2&nbsp;</strong>&nbsp;如果我们或我们的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人数据有可能作为此类交易的一部分而被转移，我们将确保该等数据在转移时的机密性，并尽最大可能确保新的持有您个人数据的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
<p><strong>3.3&nbsp;我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会披露您的个人数据：</strong></p>
<p>(a)&nbsp;根据您的需求，在您明确同意的披露方式下披露您所指定的信息。</p>
<p>(b)&nbsp;根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您信息的情况下，我们可能会依据所要求的信息类型和披露方式披露您的信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求接收方必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。</p>
<p><strong>3.4&nbsp;若您的信息属于前文所述&ldquo;征得授权同意的例外&rdquo;情形时，我们可无需事先征得个人数据主体的授权同意即共享、转让、公开披露个人数据。</strong></p>
<p><strong>3.5&nbsp;根据法律规定，共享、转让、披露经去标识化处理的个人数据，且确保数据接收方无法复原并重新识别信息主体的，不属于个人数据的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</strong></p>
<p>&nbsp;</p>
<ol start="4">
<li><strong>您的权利及行使方式</strong></li>
</ol>
<p>4.1&nbsp;您应确保提交的所有个人数据都准确无误。MetaIO会尽力维护个人数据的准确和完整，并及时更新这些数据。</p>
<p>4.2&nbsp;当适用的法律要求的情况下，您可能：</p>
<p>(a)有权访问我们持有的关于您的特定的个人数据。</p>
<p>(b)要求我们更新或更正您的不准确的个人数据。</p>
<p>(c)拒绝或限制我们使用您的个人数据。</p>
<p>(d)要求我们删除您的个人数据。</p>
<p>(e)MetaIO基于您的同意处理您的个人数据时，您还有权随时撤销您的同意。但撤销同意不会影响撤销前我们基于您的同意处理您个人数据的合法性及效力，也不影响我们基于其他适当的正当性基础处理您的个人数据。</p>
<p>(f)有权随时要求注销您的账号。当您注销账号后，我们将删除或匿名化处理你的个人数据。</p>
<p>4.3&nbsp;如果您想行使相关的权利，请通过MetaIO官方网站所载联系方式与我们联系。为保障安全，您可能需要提供书面请求。如果我们有合理依据认为这些请求存在欺骗性、无法实行或损害他人隐私权，我们则会拒绝处理请求。</p>
<p>&nbsp;</p>
<ol start="5">
<li><strong>MetaIO如何保护和留存您的个人数据</strong></li>
</ol>
<p>5.1&nbsp;MetaIO重视个人数据的安全。我们会采用适当的物理、管理和技术保障措施来保护您的个人数据不被未经授权访问、披露、使用、修改、损坏或丢失。例如，我们会使用加密技术确保数据的机密性；我们会使用保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人数据；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人数据重要性的认识。&nbsp;<strong>我们会尽力保护您的个人数据，但是请注意任何安全措施都无法做到无懈可击。</strong></p>
<p>5.2&nbsp;我们将会在达成本政策所述目的所需的期限内保留您的个人数据，除非按照法律要求或许可需要延长保留期或受到法律的允许。因为基于不同的场景和产品及服务的不同，数据的存储期可能会有所不同，我们用于确定存留期的标准包括：完成该业务目的需要留存个人数据的时间，包括提供产品和服务，维护相应的交易及业务记录，管控并提升产品与服务性能与质量，保证系统、产品和服务的安全，应对可能的用户查询或投诉，问题定位等；用户是否同意更长的留存期间；法律、合同等是否有保留数据的特殊要求等。&nbsp;<strong>只要您的账户是为您提供服务必须，我们都将保留您的注册信息。您也可以选择注销您的账号，在您注销账户或主动删除上述信息后，我们不会再对您的个人数据进行商业化使用，但我们可能会对您的个人数据进行匿名化处理后使用。</strong></p>
<p>&nbsp;</p>
<ol start="6">
<li><strong>MetaIO如何处理未成年人的个人数据</strong></li>
</ol>
<p>我们的产品、网站和服务主要面向成年人。如果没有父母或监护人的同意，未成年人不得创建自己的用户帐户。对于经父母同意而收集未成年人个人数据的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或披露此数据。 如果MetaIO发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人数据，则会设法尽快删除相关数据。如果您是父母或监护人，并且认为您的未成年人未经您的同意向我们提供了个人数据，请通过MetaIO官方网站所载联系方式联系我们进行删除。</p>
<p>&nbsp;</p>
<ol start="7">
<li><strong>您的个人数据如何在全球范围转移</strong></li>
</ol>
<p>原则上，我们在中国境内收集和产生的个人数据，将存储在中国境内。如部分产品或服务涉及跨境，我们需要向境外传输您的个人数据，我们会严格按照法律法规的规定执行，并保证您的个人数据安全。</p>
<p>&nbsp;</p>
<ol start="8">
<li><strong>本政策如何更新</strong></li>
</ol>
<p>MetaIO保留不时更新或修改本政策的权利。如果我们的隐私政策变更，我们会将最新版隐私政策发布在这里。如果我们对隐私政策做出了重大变更，我们还可能会通过不同渠道向您发送变更通知，例如，在我们的网站上发布通知或者向您发布单独的通知。</p>
<p>&nbsp;</p>
<ol start="9">
<li><strong>如何联系MetaIO</strong></li>
</ol>
<p><strong>如果您有任何疑问、意见或建议，请通过发送邮件至</strong>&nbsp;<a href="mailto:51legal@51aes.com"><u>gwyzm@metaio.cc&nbsp;</u></a><strong>或拨打13311205837与我们联系。</strong></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>元宇未来科技（北京）有限公司</p>
<p>2022年7月1日</p>
<p>&nbsp;</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacyAgreement",
  methods: {},
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
::v-deep #app{
    overflow-y: scroll;
  }
.privacy-agreement {
  color: #000;
  width: calc(100% - 400px);
  margin: 179px auto 151px;
  padding-bottom: 100px;
  overflow-y: scroll;
  .title {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 59px;
    text-align: center;
  }
  .privacy-content {
    font-size: 34px;
    h5 {
      color: #000;
      font-weight: 700;
      margin: 20px 0;
      &.big {
        font-size: 48px;
      }
    }
    p {
      color: #606060;
      &.right {
        text-align: right;
        margin-top: 30px;
      }
      &:last-child {
        padding-bottom: 100px;
      }
    }
  }
}
</style>
